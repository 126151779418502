.ratingComponent.bkGrundDiv{
    display: flex;
    width: 100%;
    background-color: #fafafa;
    padding: 73px 10px 52px 10px;
}

.ratingComponent .ratingCont{
    background: rgb(255, 255, 255);
    border-radius: 4px;
    margin: auto;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    min-height: 40vh;
}
.ratingComponent.component-loader .ratingCont {
    opacity: 0;
}

body.ltr .ratingComponent .error-validition,
body.rtl .ratingComponent .error-validition{
    margin: 0 10px;
}

.ratingComponent .headDiv, .ratingComponent .rateDiv, .ratingComponent .detailsDiv, .ratingComponent .complainsDiv{
    border-bottom: 1px solid rgb(236, 237, 240);
    border-width: 2px;
}
.ratingComponent h1{
    font-weight: bold;
}
.ratingComponent p{
    color: rgb(33, 33, 33);
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0px;
}
.ratingComponent .headDiv{
    padding: 22px 5px 21px;
}
.ratingComponent .headDiv h1, .ratingComponent h2{
    color: rgb(18, 18, 18);
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 29px;
}

.ratingComponent .ratingAct{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.ratingComponent .tripInfo, .ratingComponent .ratingOpt{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 90%;
}
.ratingComponent .tripInfo{
    margin-bottom: 23px;
    line-height: 20px;
    margin-inline-start: 25px;
    
}
.ratingComponent .tripInfo p:first-child {
    margin-inline-end: 27px
  }
.ratingComponent .tripInfo span{
    color: rgb(16, 16, 16);
    font-size: 14px;
    font-weight: 600;
}
.ratingComponent .ratingOpt{
    margin: 0 auto 22px;
}
.ratingComponent .ratingOpt p{
    font-weight: bold;
    margin: 0 35px;
}
.ratingComponent .ratingStars{
    color: rgb(252, 153, 0);
    display: flex;
    direction: ltr;
    cursor: pointer;
}
.ratingComponent .material-icons{
    font-family: Material Icons;
    font-size: 34px;    
}
.ratingComponent .rateBtn:hover{
    background-color: #179e07;
}
.ratingComponent .rateBtn{
    background: rgb(76, 183, 73);
    border-radius: 2px;
    height: 44px;
    width: 450px;
    max-width: 80%;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: bold;
}


.ratingComponent .rateDiv{
    padding-top: 27px;
    padding-bottom: 26px;
}
.ratingComponent .detailsDiv, .ratingComponent .complainsDiv{
    padding-right: 45px;
    padding-left: 45px;
    text-align: right;
}
body.ltr .ratingComponent .detailsDiv, body.ltr .ratingComponent .complainsDiv{
    text-align: left;
}
.ratingComponent .detailsDiv{
    padding-top: 15px;
    padding-bottom: 16px; 
}
.ratingComponent .fa-check {
    color: #fff;
    font-size: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-left: 5px;
    background-color: #1eb10d;
    align-items: center;
    display: inline-flex;
    justify-content: center;
}
body.ltr .ratingComponent .fa-check{
    margin-right: 5px;
    margin-left: 0px;
}
.ratingComponent .tgoTitle{
    color: rgb(30, 177, 13);
    font-size: 13px;
    font-weight: 600;
}

.ratingComponent .tripDate{
    font-size: 14px; 
}
body.ltr .ratingComponent .date{
    line-height: 2;
}
body.ltr .ratingComponent .tripTime{
    line-height: 1.5;
}
.ratingComponent .tripTime span{
    font-family: HelveticaNeue-Bold;
    font-size: 24px;
    font-weight: bold;  
}
body.ltr .ratingComponent .tDetails{
    line-height: 1.5;
}
.ratingComponent .tripDetails{
    color: rgb(16, 16, 16);
}
.ratingComponent .complainsDiv{
    padding-top: 21px;
    padding-bottom: 22px;
}
.ratingComponent .contactInfo h3{
    font-size: 18px;
    color: #212121;
    font-weight: 700;
    margin-bottom: 4px;
}
.ratingComponent .contactInfo p{
    line-height: 32px;
}
.ratingComponent .contactInfo a{
    color: #6d90ac;
    font-weight: 700;
    text-decoration: underline;    
}