.seat {
  position: absolute;
  width: 30px;
  height: 30px;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  line-height: 30px;
  font-size: 11px;
  font-weight: 700;
  /* margin: 6px 6px 0 0; */
  display: inline-flex;
  justify-content: center;
  color: #94A4B3;
}

.seat.open {
  background-image: url(./images/seats/seat-ava.png);
  cursor: pointer;
}

.seat.held {
  background-image: url(./images/seats/seat-not.png);
  color: #838486 !important;

}

.seat.selected {
  background-image: url(./images/seats/seat-selected.png);
  color: #fff;
}

.seat.driver {
  background-image: url(./images/seats/driver.png);
  pointer-events: none;
}