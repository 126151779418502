.news_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
}

.load_more {
  visibility: hidden;
}

.bounce {
  visibility: visible;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(40%);
  }

  100% {
    transform: translateY(0%);
  }
}
