.global_modal {
  z-index: 9999;
}

.paper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  padding: 10px;
  float: left;
}

@media screen and (max-height: 540px) {
  .paper {
    top: 60%;
  }
}

@media screen and (min-height: 600px) {
  .paper {
    top: 55%;
  }
}

@media screen and (min-height: 1024px) {
  .paper {
    top: 45%;
  }
}

/* @media screen and (max-width: 999px) {
  .paper {
    top: 55%;
  }
}

@media screen and (max-width: 1024px) {
  .paper {
    top: 50%;
  }
} */