.slick-list {
  width: 100%;
}

.slick-dots {
  position: absolute;
  color: white;
  top: -22px;
  right: 88px;
  display: flex !important;
}

body.ltr .slick-dots {
  left: 88px;
  right: 0;
}

.slick-dots li button {
  background: rgb(187, 201, 217);
  height: 8px;
  width: 8px;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 0;
}

.slick-dots .slick-active button {
  background: rgb(252, 153, 0);
}