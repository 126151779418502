.bus {
  position: absolute;
  transform: rotate(-90deg) translateX(-200px); /* translate(83px, 50%); */
  /* left: -3vw; */
}

.bus_en {
  position: absolute;
  transform: rotate(-90deg) translateX(-200px); /* translate(83px, -50%); */
}

@media screen and (max-width: 769px) {
    .bus {
      position: relative;
      transform: unset;
    }
    .bus_en {
      position: relative;
      transform: unset;
    }
}

.loader {
  position: relative;
}

.loader::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.74);
  z-index: 1;
}

.loader::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  border: 3px solid rgba(9, 56, 128, 0.72);
  border-top: 3px solid #ffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  z-index: 2;
  margin-top: -15px;
  margin-left: -15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}