.carousel {
  position: relative;
  height: 60vh;
  width: 60vw;
}

@media screen and (max-width: 999px) {
  .carousel {
    /* height: 60vh; */
    width: 80vh;
  }
}

@media screen and (max-width: 768px) {
  .carousel {
    width: 65vh;
  }
}

@media screen and (max-width: 470px) {
  .carousel {
    width: 40vh;
  }
}

.carousel div i {
  position: absolute;
  height: 100%;
  width: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  right: 0;
  color: white;
  font-size: 28px;
  z-index: 2;
}

.carousel div i:first-of-type {
  left: 0;
  position: absolute;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}
.carousel div i:nth-of-type(2) {
  right: 0;
  position: absolute;
  height: 100%;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}

.carousel div {
  display: flex;
  border-radius: 5px;
  height: 100%;
}

.carousel div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carouselHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 18px;
}

.carouselHeader__left {
  display: flex;
  align-items: center;
  z-index: 999999; /*Quick Handler to fix close button in english layout*/
}

.carouselHeader__right {
  display: flex;
  align-items: center;
}